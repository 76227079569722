import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';

interface Props {
    isVisible: boolean;
    isBaxter?: boolean;
}

export const Container = styled.div<Props>`
    position: absolute;
    z-index: ${({ isVisible }): string => (isVisible ? LAYER.x7 : '0')};
    top: 0;
    right: 0;
    left: ${({ isVisible }): string => (isVisible ? '50%' : '0')};
    width: ${({ isVisible }): string => (isVisible ? 'auto' : '0')};
    height: ${({ isVisible }): string => (isVisible ? '200px' : '0')};
    margin-left: ${({ isVisible }): string => (isVisible ? '-960px' : '0')};
    overflow: ${({ isVisible }): string => (isVisible ? 'auto' : 'hidden')};
    opacity: ${({ isVisible }): string => (isVisible ? '1' : '0')};
`;
