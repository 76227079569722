/**
 * Return capitalized string
 *
 * @param test string
 * @param variant 'full' | 'partial'.
 * @default 'full'
 * @description Both variant will make first letter capitalize but full variant will lowercase rest of the text and partial variant will leave rest of the text unchanged.
 */
export const stringCapitalize: (text: string, variant?: 'full' | 'partial') => string = (
    text,
    variant = 'full',
): string => text.charAt(0).toUpperCase() + (variant === 'full' ? text.slice(1).toLowerCase() : text.slice(1));
