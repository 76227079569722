import type { ArrowsVisibility } from '@domains/shared/components/Slider/types/arrowsVisibility';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback, useRef, useState } from 'react';

import { SliderButtons } from './components/SliderButtons/SliderButtons';
import { Container, List, ListItem } from './Slider.theme';

interface Props<Item extends { id: string }> {
    items: Item[];
    renderItem: (item: Item) => JSX.Element;
    trackingTouchPointButton?: string;
    buttonTopPosition?: number;
    shouldAllowUnequalItems?: boolean;
    className?: string;
    shouldUseEdgePaddings?: boolean;
    arrowsVisibility?: ArrowsVisibility;
}

export const Slider = <Item extends { id: string }>({
    items,
    renderItem,
    trackingTouchPointButton,
    buttonTopPosition,
    shouldAllowUnequalItems = false,
    shouldUseEdgePaddings = false,
    className,
    arrowsVisibility = 'desktop',
}: Props<Item>): JSX.Element => {
    const listRef = useRef<HTMLUListElement>(null);
    const [isUserSwipe, setIsUserSwipe] = useState(false);
    const { trackEvent } = useTracking();

    const trackSwipeEvent = (): void => {
        setIsUserSwipe(true);
    };

    const trackingEventCallback = useCallback(() => {
        if (trackingTouchPointButton) {
            trackEvent('carousel_swipe', {
                touch_point_button: trackingTouchPointButton,
            });
        }
    }, [trackEvent, trackingTouchPointButton]);

    const trackSwipeEventFinish = (): void => {
        if (isUserSwipe) {
            trackingEventCallback();
            setIsUserSwipe(false);
        }
    };

    return (
        <Container>
            <SliderButtons
                listRef={listRef}
                numberOfItems={items.length}
                top={buttonTopPosition}
                trackingEventCallback={trackingEventCallback}
                arrowsVisibility={arrowsVisibility}
            />
            <List ref={listRef} onTouchMove={trackSwipeEvent} onTouchEnd={trackSwipeEventFinish} className={className}>
                {items.map((item) => (
                    <ListItem
                        key={item.id}
                        shouldAllowUnequalItems={shouldAllowUnequalItems}
                        shouldUseEdgePaddings={shouldUseEdgePaddings}
                    >
                        {renderItem(item)}
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};
