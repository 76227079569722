import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import type { JSX } from 'react';

import { Image, Label, Tile, TILE_WIDTH } from './RealEstateSliderTile.theme';

export interface RealEstateSliderTileProps {
    id: string;
    label: string;
    title: string;
    url: string;
    image: string;
    onClick?: () => void;
}

export const RealEstateSliderTile = ({
    label,
    title,
    url,
    image,
    onClick,
    ...rest
}: RealEstateSliderTileProps): JSX.Element => {
    return (
        <LocalLink href={url} passHref>
            <Tile title={title} onClick={onClick} {...rest}>
                <Image src={image} alt={title} width={TILE_WIDTH} height="200" />
                <Label variant="bold" as="span">
                    {label}
                </Label>
            </Tile>
        </LocalLink>
    );
};
