import { fetchExternalAds } from '@lib/fetchExternalAds';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { AdSlot } from 'react-dfp';

import { AdSlotContainer } from './AdUnit.theme';

type Props = JSX.IntrinsicElements['div'] & {
    slotWidth?: string;
    sizes: ([number, number] | string)[];
    slotId: string;
    sizeMapping?: { viewport: [number, number]; sizes: ([number, number] | string)[] }[];
    adUnit?: string;
};

const AdUnitBase: ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { slotWidth, sizes, sizeMapping, adUnit, slotId, ...htmlProps },
    ref,
) => {
    const [isAdvertisementReady, setIsAdvertisementReady] = useState(false);

    useEffect(() => {
        let isMounted = true;

        fetchExternalAds('load', slotId)
            // in case of SPA session we load ads properly so fetchExternalAds resolves
            .then(() => {
                if (isMounted) {
                    setIsAdvertisementReady(true);
                }
            })
            .catch(() => {
                // note: we don't need catch action as we have warning from fetchExternalAds
            });

        return (): void => {
            isMounted = false;
        };
    }, [adUnit, isAdvertisementReady, slotId]);

    // some SITEs don't have specific AdUnits. To simplify the AdPage template, Advertisement will simply not render anything if adUnit is empty
    return !adUnit || !isAdvertisementReady ? null : (
        <AdSlotContainer slotWidth={slotWidth} {...htmlProps} ref={ref}>
            <AdSlot slotId={slotId} sizes={sizes} adUnit={adUnit} sizeMapping={sizeMapping} />
        </AdSlotContainer>
    );
};

export const AdUnit = forwardRef<HTMLDivElement, Props>(AdUnitBase);
