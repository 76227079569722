import type { ArrowsVisibility } from '@domains/shared/components/Slider/types/arrowsVisibility';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import type { RefObject } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTimeoutFn } from 'react-use';

interface Props {
    listRef: RefObject<HTMLUListElement>;
    numberOfItems: number;
    arrowsVisibility: ArrowsVisibility;
}

interface ReturnProps {
    shouldShowButtons: boolean;
    shouldDisablePreviousButton: boolean;
    shouldDisableNextButton: boolean;
    onNextCallback: () => void;
    onPreviousCallback: () => void;
}

const getShouldEnableButtons = ({
    arrowsVisibility,
    isDesktop,
    isTablet,
}: {
    arrowsVisibility: ArrowsVisibility;
    isDesktop: boolean | null;
    isTablet: boolean | null;
}): boolean => {
    switch (arrowsVisibility) {
        case 'none': {
            return false;
        }
        case 'desktop': {
            return isDesktop === true;
        }
        case 'desktopAndTablet': {
            return isDesktop === true || isTablet === true;
        }
    }
};

export const useSliderButtons = ({ listRef, numberOfItems, arrowsVisibility }: Props): ReturnProps => {
    const listItem = useRef<{
        width: number;
        gap: number;
        numberOfVisibleItems: number;
    }>({
        width: 0,
        gap: 0,
        numberOfVisibleItems: 0,
    });

    const { isDesktop, isTablet } = useContext(RWDContext);
    const [shouldShowButtons, setShouldShowButtons] = useState(false);
    const [shouldDisablePreviousButton, setShouldDisablePreviousButton] = useState(true);
    const [shouldDisableNextButton, setShouldDisableNextButton] = useState(false);

    const shouldEnableButtons = getShouldEnableButtons({
        arrowsVisibility,
        isDesktop,
        isTablet,
    });

    const getIndexOfFirstFullyVisibleItem = useCallback(() => {
        if (!listItem.current || !listRef.current) {
            return;
        }

        const { scrollLeft } = listRef.current;
        const { width, gap } = listItem.current;

        if (!width) {
            return;
        }

        return Math.ceil(scrollLeft / (width + gap));
    }, [listRef]);

    const getIndexOfLastFullyVisibleItem = useCallback(() => {
        if (!listItem.current || !listRef.current) {
            return;
        }

        const { clientWidth, scrollLeft } = listRef.current;
        const { width, gap } = listItem.current;

        if (!width) {
            return;
        }

        return Math.floor((clientWidth + scrollLeft + gap) / (width + gap));
    }, [listRef]);

    const onPreviousCallback = useCallback(() => {
        const firstFullyVisibleTile = getIndexOfFirstFullyVisibleItem();

        if (!listRef.current?.scrollTo || !firstFullyVisibleTile) {
            return;
        }

        const { width, gap, numberOfVisibleItems } = listItem.current;

        listRef.current.scrollTo((firstFullyVisibleTile - numberOfVisibleItems) * (width + gap), 0);
    }, [getIndexOfFirstFullyVisibleItem, listRef]);

    const onNextCallback = useCallback(() => {
        const lastFullyVisibleTile = getIndexOfLastFullyVisibleItem();

        if (!listRef.current?.scrollTo || !lastFullyVisibleTile) {
            return;
        }

        const { clientWidth } = listRef.current;
        const { width, gap, numberOfVisibleItems } = listItem.current;

        listRef.current.scrollTo((lastFullyVisibleTile + numberOfVisibleItems) * (width + gap) - clientWidth - gap, 0);
    }, [getIndexOfLastFullyVisibleItem, listRef]);

    const checkButtonsAvailability = useCallback(() => {
        if (listRef.current && listItem.current.width) {
            const listItemsLength = listRef.current.children.length;

            setShouldDisableNextButton(getIndexOfLastFullyVisibleItem() === listItemsLength);
            setShouldDisablePreviousButton(getIndexOfFirstFullyVisibleItem() === 0);
        }
    }, [getIndexOfFirstFullyVisibleItem, getIndexOfLastFullyVisibleItem, listRef]);

    const resetButtonsAvailability = useTimeoutFn(checkButtonsAvailability, 200)[2];

    const checkButtonsVisibility = useCallback(() => {
        if (listRef.current) {
            const listItems = listRef.current.children;

            if (listItems.length === 0) {
                return;
            }

            const width = listItems[0].clientWidth;
            const gap = Number.parseInt(window.getComputedStyle(listRef.current, null).gap, 10);
            const totalWidth = (width + gap) * listItems.length - gap;
            const numberOfVisibleItems = Math.floor((listRef.current.clientWidth + gap) / (width + gap));
            const areButtonsVisible = shouldEnableButtons && totalWidth > listRef.current.clientWidth;

            listItem.current = {
                width,
                gap,
                numberOfVisibleItems,
            };

            setShouldShowButtons(areButtonsVisible);

            if (areButtonsVisible) {
                resetButtonsAvailability();
            }
        }
    }, [shouldEnableButtons, listRef, resetButtonsAvailability]);

    const resetButtonsVisibility = useTimeoutFn(checkButtonsVisibility, 200)[2];

    useEffect(() => {
        const listElement = listRef.current;

        if (!shouldEnableButtons) {
            setShouldShowButtons(false);

            return;
        }

        if (listElement) {
            resetButtonsVisibility();

            window.addEventListener('resize', resetButtonsVisibility, false);

            if (shouldShowButtons) {
                listElement.addEventListener('scroll', resetButtonsAvailability, false);
            }

            return (): void => {
                window.removeEventListener('resize', resetButtonsVisibility, false);
                listElement.removeEventListener('scroll', resetButtonsAvailability, false);
            };
        }
    }, [
        resetButtonsAvailability,
        resetButtonsVisibility,
        shouldEnableButtons,
        listRef,
        shouldShowButtons,
        numberOfItems,
    ]);

    useIntersection(listRef, () => {
        checkButtonsVisibility();
    });

    return useMemo(
        () => ({
            shouldShowButtons,
            shouldDisablePreviousButton,
            shouldDisableNextButton,
            onNextCallback,
            onPreviousCallback,
        }),
        [onNextCallback, onPreviousCallback, shouldDisableNextButton, shouldDisablePreviousButton, shouldShowButtons],
    );
};
