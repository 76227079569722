import { EXPERIMENT } from '@domains/shared/consts/experiment';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';

export const useBaxterAdvertising = (): void => {
    const { isVariantEnabled } = useExperiments();

    // Initialize all EUADS experiments
    const euadsExperiments = Object.keys(EXPERIMENT).filter((key) => key.startsWith('euads'));
    for (const key of euadsExperiments) isVariantEnabled(EXPERIMENT[key as keyof typeof EXPERIMENT], 'a');
};
