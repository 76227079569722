import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Container = styled.div`
    position: relative;
`;

export const List = styled.ul`
    display: flex;
    align-items: stretch;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    list-style: none;
    gap: ${theme.space[16]};
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ListItem = styled.li<{
    shouldAllowUnequalItems: boolean;
    shouldUseEdgePaddings: boolean;
}>`
    display: flex;
    flex: ${({ shouldAllowUnequalItems }): CSSProperties['flex'] => (shouldAllowUnequalItems ? '0 0 auto' : 'auto')};

    ${({ shouldUseEdgePaddings }): string | boolean =>
        shouldUseEdgePaddings &&
        `
        &:first-of-type {
            padding-left: ${theme.space[16]};
        }
        &:last-of-type {
            padding-right: ${theme.space[16]};
        }
    
        @media ${theme.mediaQueries.mdMin} {
            &:first-of-type {
                padding-left: 0;
            }
            &:last-of-type {
                padding-right: 0;
            }
        }
    `}
`;
