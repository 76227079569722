import { ContentBackground } from '@lib/pages/contentBackground';
import { MainHeader } from '@widgets/header/MainHeader';
import type { JSX, PropsWithChildren } from 'react';

import { Footer } from './components/Footer';
import { Masthead } from './components/Masthead';
import { Content } from './MainLayout.theme';

interface Props {
    isMinimal?: boolean;
    contentBackground?: ContentBackground;
    hasStickyPageHeader?: boolean;
    investmentId?: string;
    adUnitOverHeader?: string;
    shouldDisplayFooter?: boolean;
}

export const MainLayout = ({
    children,
    isMinimal = false,
    hasStickyPageHeader = true,
    contentBackground = ContentBackground.default,
    investmentId = '',
    adUnitOverHeader,
    shouldDisplayFooter = true,
}: PropsWithChildren<Props>): JSX.Element => {
    return (
        <>
            {adUnitOverHeader ? <Masthead adUnit={adUnitOverHeader} /> : null}
            <MainHeader
                isSticky={hasStickyPageHeader}
                investmentId={investmentId}
                adUnitOverHeader={adUnitOverHeader}
            />
            <Content background={contentBackground} isMinimal={isMinimal}>
                {children}
            </Content>
            {shouldDisplayFooter ? <Footer /> : null}
        </>
    );
};
