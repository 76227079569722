import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import type { ButtonVariant } from './LinkButton';

type ColorsProps = {
    [key in ButtonVariant]: {
        boxShadow: string;
        text: string;
        background: string;
        activeText: string;
    };
};

const COLORS: ColorsProps = {
    primary: {
        boxShadow: theme.colors['borders-brand-primary'],
        text: theme.colors['text-global-inverse'],
        background: theme.colors['background-brand-primary'],
        activeText: theme.colors['text-brand-primary'],
    },
    secondary: {
        boxShadow: theme.colors['borders-brand-primary'],
        text: theme.colors['text-brand-primary'],
        background: 'transparent',
        activeText: theme.colors['text-global-inverse'],
    },
    link: {
        boxShadow: 'inherit',
        text: theme.colors['text-brand-primary'],
        background: 'transparent',
        activeText: 'inherit',
    },
};

export const StyledLinkButton = styled.a<{ variant: ButtonVariant }>`
    display: inline-block;
    border-radius: ${theme.borderWidths[4]};
    background-color: ${({ variant }): string => COLORS[variant].background};
    color: ${({ variant }): string => COLORS[variant].text};
    font-size: ${theme.fontSizes[16]};
    font-weight: ${theme.fontWeights[700]};
    letter-spacing: 0.7px;
    line-height: ${theme.lineHeights[20]};
    text-align: center;
    text-decoration: none;

    ${({ variant }): SerializedStyles | undefined =>
        variant === 'primary' || variant === 'secondary'
            ? css`
                  padding: ${theme.space[14]} ${theme.space[32]};
                  box-shadow: inset 0 0 0 3px ${COLORS[variant].boxShadow};
              `
            : undefined}

    ${({ variant }): SerializedStyles | undefined =>
        variant === 'link'
            ? css`
                  padding: ${theme.space[14]} 0 ${theme.space[8]} 0;
                  background: transparent;
                  box-shadow: none;

                  ::after {
                      content: ' ';
                      display: block;
                      width: 100%;
                      margin: ${theme.space[4]} auto 0;
                      transition: transform 0.15s;
                      border-bottom: ${theme.borderWidths[2]} ${theme.borderStyles.solid};
                  }
              `
            : undefined}

    &:hover {
        ${({ variant }): SerializedStyles | undefined =>
            variant === 'primary' || variant === 'secondary'
                ? css`
                      background: transparent;
                      box-shadow: inset 0 0 0 5px ${COLORS[variant].boxShadow};
                      color: ${theme.colors['text-brand-primary']};
                  `
                : undefined}

        ${({ variant }): SerializedStyles | undefined =>
            variant === 'link'
                ? css`
                      ::after {
                          transform: scaleX(0.5);
                      }
                  `
                : undefined}
    }

    &:active {
        ${({ variant }): SerializedStyles | undefined =>
            variant === 'primary' || variant === 'secondary'
                ? css`
                      background: ${theme.colors['background-brand-primary-light']};
                      box-shadow: none;
                      color: ${COLORS[variant].activeText};
                  `
                : undefined}
    }

    &:focus-visible:not(:active, :hover) {
        outline: ${theme.sizes[4]} ${theme.borderStyles.solid} ${theme.colors['background-brand-chat-highlight']};
        box-shadow: none;
    }
`;
