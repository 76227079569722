import type { ComponentProps, ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import { StyledLinkButton } from './LinkButton.theme';

export type ButtonVariant = 'primary' | 'secondary' | 'link';

interface Props extends ComponentProps<'a'> {
    variant?: ButtonVariant;
    as?: React.ElementType;
}

/**
 * This component is looks the same as Button from nexus but instead of rendering <button /> it renders <a/>
 */

const LinkButtonElement: ForwardRefRenderFunction<HTMLAnchorElement, Props> = (
    { variant = 'primary', children, ...htmlProps },
    ref,
) => {
    return (
        <StyledLinkButton {...htmlProps} variant={variant} ref={ref}>
            {children}
        </StyledLinkButton>
    );
};

export const LinkButton = forwardRef<HTMLAnchorElement, Props>(LinkButtonElement);
