import type { JSX } from 'react';
import { Fragment } from 'react';

export const replacePlaceholders = (
    description: string,
    wrapper: (textChunk: string, index: number) => JSX.Element | string,
): JSX.Element[] | string => {
    return description
        .split('<C>')
        .map(
            (textFragment, index): JSX.Element =>
                index % 2 === 0 ? (
                    <Fragment key={index}>{textFragment}</Fragment>
                ) : (
                    <Fragment key={index}>{wrapper(textFragment, index)}</Fragment>
                ),
        );
};
