import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

export const Buttons = styled.div<{ top?: number }>`
    display: flex;
    position: absolute;
    top: ${({ top }): CSSProperties['top'] => (top ? `${top}px` : '-56px')};
    right: 0;
`;

export const StyledButton = styled(Button)`
    width: ${theme.space[20]};
    min-height: ${theme.space[48]};
    padding-inline: ${theme.space[0]};
`;
