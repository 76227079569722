/*
Please use descriptive keys relating to the experiment name (e.g. "regularGeoBasedPricing")
rather than plain JIRA IDs such as "eure21370"
*/
export const EXPERIMENT = {
    /** wording experiment for PPE banner on listing */
    eure26607: 'EURE-26607',
    euads4830: 'EUADS-4830',
    euads5235: 'EUADS-5235',
    /** Draw on map entrypoint in picker */
    eure27417: 'EURE-27417',
    remd1298: 'REMD-1298',
    repm914: 'REPM-914',
    smr3411: 'SMR-3411',
    regularGeoBasedPricing: 'EURE-21103',
} as const;
