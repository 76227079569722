import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';

interface AdSlotProps {
    slotWidth?: string;
}

export const AdSlotContainer = styled.div<AdSlotProps>`
    display: block;
    position: relative;
    z-index: ${LAYER.x0};
    justify-content: center;
    width: ${({ slotWidth }): string => (slotWidth ? slotWidth : 'auto')};
    min-height: auto;
    overflow: hidden;
`;
