import type { JSX } from 'react';

interface Props {
    id: string | string[];
    className?: string;
}

export const AdvertSlot = ({ id, className }: Props): JSX.Element | null => {
    if (!id || id.length === 0) return null;

    const createAdvertSlot = (slotId: string, className?: string): JSX.Element => {
        return (
            <div
                id={slotId}
                key={slotId}
                className={className || ''}
                style={{ display: 'none' }}
                data-testid="qa-advert-slot"
                data-cy={`baxter-slot-${slotId}`}
            />
        );
    };

    if (Array.isArray(id)) {
        return <>{id.map((slotId: string, index: number) => createAdvertSlot(slotId, className?.split(',')[index]))}</>;
    }

    return <>{createAdvertSlot(id, className)}</>;
};
