import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';

interface Props {
    isSticky: boolean;
    investmentId?: string;
    adUnitOverHeader?: string;
}

const LazyNexusHeader = dynamic(() => import('@widgets/header/MainHeader/components/NexusHeader/NexusHeader'), {
    ssr: true,
});

export const MainHeader = ({ isSticky, investmentId, adUnitOverHeader }: Props): JSX.Element => {
    useBaxterAdvertising();

    return <LazyNexusHeader adUnitOverHeader={adUnitOverHeader} isSticky={isSticky} investmentId={investmentId} />;
};
