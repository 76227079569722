import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const TILE_WIDTH = 296;

export const Tile = styled.a`
    display: block;
    width: ${TILE_WIDTH}px;
    overflow: hidden;
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-primary']};
    text-decoration: none;
`;

export const Image = styled.img`
    display: block;
`;

export const Label = styled(P2)`
    display: block;
    padding: ${theme.space['28']} ${theme.space['16']};
`;
