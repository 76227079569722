import styled from '@emotion/styled';
import { ContentBackground } from '@lib/pages/contentBackground';

interface ContentProps {
    background: ContentBackground;
    isMinimal: boolean;
}

export const Content = styled.main<ContentProps>`
    flex: 1;
    width: 100%;
    margin: 0 auto;
    padding: ${({ isMinimal }): string => (isMinimal ? '72px 0 0 0' : '72px 0')};
    background: ${({ background, theme }): string =>
        background === ContentBackground.none ? 'none' : theme.colors['background-global-secondary']};
`;
