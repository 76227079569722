import { logWarn } from '@domains/shared/helpers/logger';
import { DFPManager } from 'react-dfp';

// Wrap into the promise connected with loading ads, so we can catch the error (e.g. when Ad Blocker blocks the requests).
// Otherwise, we get thousands of errors in Sentry when a user has enabled Ad Blocker
// Note: `DFPManager.getGoogletag()` is called anyway in `load` and `refresh`
// Note: See AdUnit.tsx for more details
export const fetchExternalAds = (action: 'load' | 'refresh' = 'load', slotId?: string): Promise<void> => {
    const dfpPromise = DFPManager.getGoogletag();

    dfpPromise
        .then(() => {
            DFPManager[action](slotId);
        })
        .catch((error: Record<string, unknown>) => {
            logWarn(`Ads loading error (${action})`, { ...error });
        });

    return dfpPromise;
};
