import { I18NContextProvider } from '@lib/i18n/I18nContext';
import type { Translations } from '@lib/i18n/types/translations';
import type { ComponentType, JSX } from 'react';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types -- Use {} for type compatibility
export const withTranslations = <T extends {}>(
    Component: ComponentType<T>,
    translations: Translations,
): ComponentType<T> => {
    const WithTranslations = (props: T): JSX.Element => (
        <I18NContextProvider translations={translations}>
            <Component {...props} />
        </I18NContextProvider>
    );

    return WithTranslations;
};
