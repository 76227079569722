import type { ArrowsVisibility } from '@domains/shared/components/Slider/types/arrowsVisibility';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import ArrowLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowLeft';
import ArrowRight from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowRight';
import type { JSX, RefObject } from 'react';

import { useSliderButtons } from '../../hooks/useSliderButtons';
import { Buttons, StyledButton } from './SliderButtons.theme';

interface Props {
    listRef: RefObject<HTMLUListElement>;
    numberOfItems: number;
    top?: number;
    trackingEventCallback: () => void;
    arrowsVisibility: ArrowsVisibility;
}

export const SliderButtons = ({
    listRef,
    numberOfItems,
    top,
    trackingEventCallback,
    arrowsVisibility,
}: Props): JSX.Element | null => {
    const [t] = useTranslations();

    const {
        shouldShowButtons,
        shouldDisableNextButton,
        shouldDisablePreviousButton,
        onNextCallback,
        onPreviousCallback,
    } = useSliderButtons({ listRef, numberOfItems, arrowsVisibility });

    if (!shouldShowButtons) {
        return null;
    }

    const handleClick = (callbackAction: () => void): void => {
        trackingEventCallback();
        callbackAction();
    };

    return (
        <Buttons top={top}>
            <StyledButton
                variant="flat"
                prefixIcon={ArrowLeft}
                onClick={() => handleClick(onPreviousCallback)}
                disabled={shouldDisablePreviousButton}
                aria-label={t('frontend.shared.slide.back')}
            />
            <StyledButton
                variant="flat"
                prefixIcon={ArrowRight}
                onClick={() => handleClick(onNextCallback)}
                disabled={shouldDisableNextButton}
                aria-label={t('frontend.shared.slide.forward')}
            />
        </Buttons>
    );
};
