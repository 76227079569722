import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { css, Global } from '@emotion/react';
import { Fonts } from '@lib/styles/fonts/Fonts';
import { theme } from '@lib/styles/themes/nexus';
import type { FC, JSX, PropsWithChildren } from 'react';

const FONT_FAMILY = theme.fonts.primary;

const BODY_STYLES = css`
    body {
        font-family: ${FONT_FAMILY};
    }
`;

const Styles = (): JSX.Element => {
    return <Global styles={BODY_STYLES} />;
};

const { staticAssetsPrefix } = getPublicEnvConfig();

// FontProvider provides new font (Sora) per-page until all pages are fit to use it
export const FontProvider: FC<PropsWithChildren> = ({ children }): JSX.Element => {
    return (
        <>
            <Fonts staticAssetsPrefix={staticAssetsPrefix} family={FONT_FAMILY} />
            <Styles />
            {children}
        </>
    );
};
