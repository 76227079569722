import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { AdUnit } from '@domains/shared/components/dataDisplay/AdUnit/AdUnit';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import type { JSX } from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Container } from './Masthead.theme';

interface Props {
    adUnit: string;
}

type ObserverCallback = (onLoaded: () => void, mutationsList: MutationRecord[]) => void;
const observerCallback: ObserverCallback = (onAdLoaded, mutationsList) => {
    for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.target.firstChild?.nodeName.toLowerCase() === 'iframe') {
            onAdLoaded();
        }
    }
};

export const Masthead = ({ adUnit }: Props): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const adRef = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const onAdLoaded = useCallback(() => setIsVisible(true), []);
    useBaxterAdvertising();
    const shouldShowAdContainer = Boolean(isDesktop);
    const shouldRenderBaxterDesktopAds = shouldShowAdContainer;
    const shouldRenderAdUnit = !isDesktop;

    useEffect(() => {
        if (!adRef.current || !isDesktop) {
            return;
        }

        const observer = new MutationObserver(observerCallback.bind(null, onAdLoaded));

        observer.observe(adRef.current, { subtree: true, childList: true });

        return (): void => {
            observer.disconnect();
        };
    }, [onAdLoaded, isDesktop]);

    return (
        <>
            <Container isVisible={isVisible || shouldShowAdContainer} ref={adRef}>
                {shouldRenderAdUnit ? (
                    <AdUnit
                        data-cy="search-page-top-ad-unit"
                        slotId="search-page-top-ad-unit"
                        adUnit={adUnit}
                        sizes={[[1920, 200]]}
                    />
                ) : null}
                {shouldRenderBaxterDesktopAds ? (
                    <>
                        <AdvertSlot id="baxter-l-nav-top" />
                        <AdvertSlot id="baxter-l-scr" />
                    </>
                ) : null}
            </Container>
        </>
    );
};
